import Layout from 'components/layout';
import Seo from 'components/seo';
import React from 'react';
import Blog from 'sections/blog';
import Banner from '../section-adelphatech-lescalator/banner';
import HowItWork from '../section-adelphatech-lescalator/how-it-work';
import Process from '../section-adelphatech-lescalator/process';
import ServicePage from '../section-adelphatech-lescalator/service';
import Partners from '../sections/partners';
import Subscribe from '../sections/subscribe';


export default function AdelphatechLescalatorPage() {
    return (
        <Layout>
            <Seo
                title="AdelphaTech"
                description="is a leading web & mobile application development firm based in Toronto, Canada that offers custom web, and mobile app development services."
            />
            <Banner />
            <Partners />
            <HowItWork />
            <ServicePage />
            <Process />
            <Blog />
            <Subscribe />
        </Layout>
    )
};