
import React from 'react';
import { Box, Container, Heading } from "theme-ui";
import Accordion from 'components/accordion/accordion';
import SectionHeading from '../components/section-heading';

const data = [
    {
        id: 1,
        title: `01. Discovery - Comprehensive research and consultation.`,
        contents: (
            <div className='text-dark'>
                We begin with an initial consultation to fully understand your vision and goals. Our team conducts comprehensive market research and competitor analysis to provide a solid foundation for your project, ensuring alignment with market demands and opportunities.
            </div>
        ),
    },
    {
        id: 2,
        title: `02. Define - Core features and planning. `,
        contents: (
            <div>
                Next, we identify the core features and functionalities essential to your product. Our experts create a detailed MVP plan and timeline, outlining each phase of development to ensure efficient progress and clear milestones, setting the stage for successful implementation.
            </div>
        ),
    },
    {
        id: 3,
        title: `03. Design - Wireframes and interactive mockups.`,
        contents: (
            <div>
                In this step, we develop wireframes and comprehensive UI/UX designs that prioritize user experience. Interactive mockups are created to visualize the final product, allowing for adjustments and refinements based on client feedback before actual development begins.
            </div>
        ),
    },
    {
        id: 4,
        title: `04. Develop - Rapid development and iteration.`,
        contents: (
            <div>
                We rapidly develop the product using the latest technologies and agile methodologies. Continuous iterations are implemented based on user feedback and testing, ensuring that each version of the product meets the highest standards of quality and functionality.
            </div>
        ),
    },
    {
        id: 5,
        title: `05. Deploy - Launch and performance monitoring.`,
        contents: (
            <div>
                Your MVP is launched to a carefully selected user base, allowing for real-world testing and performance monitoring. We gather detailed user feedback and performance metrics to identify any areas for improvement and ensure the product is ready for broader release.
            </div>
        ),
    },
    {
        id: 6,
        title: `06. Iterate - Refinement and ongoing improvement.`,
        contents: (
            <div>
                Based on the feedback collected, we refine and enhance the product, making necessary adjustments to meet user needs and expectations. This step prepares the product for subsequent phases of development, ensuring continuous improvement and long-term success.
            </div>
        ),
    },
];
const Process = () => {
    return (
        <Box as="section" className='bg-white' sx={styles.section}>
            <Container>
                <SectionHeading
                    sx={{ mb: [6, null, null, 14] }}
                    slogan="Our Process"
                    title="Efficient project development from start to finish with iterative refinement."
                />
                <Box sx={styles.grid}>
                    <Accordion items={data} />
                </Box>
            </Container>
        </Box>
    );
};

export default Process;

const styles = {
    section: {
        backgroundColor: '#fff',
        pt: [6, null, null, 12, null, 6],
        pb: [0, null, null, 0, 0, 0],
    },
    heading: {
        marginBottom: [7, null, null, 0, 0],
        p: {
            maxWidth: 490,
            margin: ['10px auto 0'],
            fontFamily: 'headingAlt',
        },
    },
    title: {
        color: 'heading',
        fontWeight: 700,
        fontSize: [5, null, null, 10, null, 6],
        lineHeight: 1.2,
        letterSpacing: ['-0.5px', null, null, '1px'],
        textAlign: ['center', null, null, 'center'],
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
        mb: 12,
    },
    loadMore: {
        paddingTop: [3, null, null, 0],
        textAlign: 'center',
        button: {
            backgroundColor: '#ECF2F7',
            color: '#738295',
            minHeight: 50,
            px: '22px',
            ':hover': {
                backgroundColor: 'primary',
                color: 'white',
            },
        },
    },
};


