/** @jsx jsx */
import SectionHeading from 'components/section-heading';
import { Box, Container, jsx } from 'theme-ui';

import ServicePartner from '../components/cards/service-partner';
import icon1 from '../assets/images/service-lescalator/1.svg';
import icon2 from '../assets/images/service-lescalator/2.svg';
import icon3 from '../assets/images/service-lescalator/3.svg';
import icon4 from '../assets/images/service-lescalator/4.svg';
import icon5 from '../assets/images/service-lescalator/5.svg';
import icon6 from '../assets/images/service-lescalator/6.svg';
const data = [
    {
        id: 1,
        icon: icon1,
        path: '#!',
        title: 'MVP Consultation',
        description: `Personalized sessions define MVP scope, provide strategic feature prioritization guidance for effective product planning.`,
    },
    {
        id: 2,
        icon: icon2,
        path: '#!',
        title: 'Rapid Prototyping',
        description: `Functional prototypes developed swiftly using agile methodologies, ensuring flexibility and speed in product development.`,
    },
    {
        id: 3,
        icon: icon3,
        path: '#!',
        title: 'User Testing',
        description: `Thorough user testing gathers actionable insights, guiding iterative improvements for enhanced user experience and product success.`,
    },
    {
        id: 4,
        icon: icon4,
        path: '#!',
        title: 'Roadmapping',
        description: `Creation of a clear product roadmap post-MVP, offering guidance on scaling your MVP to a full product.`,
    },
    {
        id: 5,
        icon: icon5,
        path: '#!',
        title: 'Market Validation',
        description: `Gather real user feedback before full-scale launch, adjusting and refining your product based on actual market needs.`,
    },
    {
        id: 6,
        icon: icon6,
        path: '#!',
        title: 'Success Stories',
        description: `Learn from successful case studies, including startups who secured funding and attracted users post-MVP launch.`,
    },
];

const ServicePage = () => {
    return (
        <section id="features" sx={styles.section}>
            <Container>
                <SectionHeading
                    sx={styles.heading}
                    slogan="Our Services"
                    title="Transforming Ideas into Reality with Efficient MVP Services"
                />
                <Box sx={styles.grid}>
                    {data?.map((item) => (
                        <ServicePartner className="feature-item" style={{ textAlign: "center" }} key={item.id} data={item} />
                    ))}
                </Box>
            </Container>
        </section>
    );
};

export default ServicePage;

const styles = {
    section: {
        backgroundColor: '#f3f6fe',
        pt: [8, null, null, 12, null, 10],
        pb: [9, null, null, 12, 16, 18],
    },
    heading: {
        marginBottom: [7, null, null, 8, 7],
        p: {
            maxWidth: 490,
            margin: ['10px auto 0'],
            fontFamily: 'headingAlt',
        },
    },
    grid: {
        gap: [6, null, 0],
        display: 'grid',
        maxWidth: 1080,
        margin: '0 auto',
        fontFamily: 'headingAlt',
        gridTemplateColumns: [
            'repeat(1, 1fr)',
            null,
            null,
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
        ],
        borderTop: (t) => [null, null, `1px solid ${t.colors.borderColor}`],
        borderLeft: (t) => [null, null, `1px solid ${t.colors.borderColor}`],
    },
    '.buttonform': {
        borderRadius: 0,
        border: ' 1px solid #09386b',
        backgroundColor: 'transparent',
        color: '#09386b',
        minHeight: '44px',
    },
};
