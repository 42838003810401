/** @jsx jsx */
import { jsx, Box, Container, Heading } from 'theme-ui';
import SectionHeading from 'components/section-heading';
import ApproachCard from 'components/cards/card-approach';

import icon1 from '../assets/images/lescalator/1.svg';
import icon2 from '../assets/images/lescalator/2.svg';
import icon3 from '../assets/images/approach/3.svg';

const data = [
    {
        id: 1,
        icon: icon1,
        path: '',
        title: 'Innovate Faster',
        description: `Streamline your product development cycle by adopting advanced methodologies to speed up market entry. Quickly validate your business ideas through rigorous testing and market analysis, ensuring feasibility and success, minimizing risks, and optimizing resource allocation for innovative growth.`,
    },
    {
        id: 2,
        icon: icon2,
        path: '',
        title: 'Cost-Effective',
        description: `Minimize development costs by prioritizing essential features, ensuring you only build what truly matters. Avoid costly mistakes by testing your concept early, allowing for adjustments and improvements before significant investments, leading to a more efficient and effective development process.`,
    },
    {
        id: 3,
        icon: icon3,
        path: '',
        title: 'Market Validation',
        description: `Gather real user feedback before a full-scale launch to ensure your product meets user expectations. Adjust and refine your product based on actual market needs, making informed improvements that increase the chances of market success and user satisfaction.`,
    },
];

const HowItWork = () => {
    return (
        <section id="approach" sx={styles.section}>
            <Container>
                <SectionHeading
                    sx={styles.heading}
                    slogan="Why Choose Our MVP Service?"
                />
                <Heading sx={styles.title}>
                    Choose our MVP service for rapid, cost-effective, and high-quality <br/> product development tailored to your needs.
                </Heading>
                <Box sx={styles.grid}>
                    {data?.map((item) => (
                        <ApproachCard className="feature-item align-items-center" key={item.id} data={item} />
                    ))}
                </Box>
            </Container>
        </section>
    );
};

export default HowItWork;

const styles = {
    section: {
        backgroundColor: '#fff',
        pt: [3, null, null, 12, null, 3],
        pb: [9, null, null, 12, 16, 10],
    },
    heading: {
        marginBottom: [7, null, null, 0, 0],
        p: {
            maxWidth: 490,
            margin: ['10px auto 0'],
            fontFamily: 'headingAlt',
        },
    },
    title: {
        color: 'heading',
        fontWeight: 700,
        fontSize: [5, null, null, 10, null, 6],
        lineHeight: 1.2,
        letterSpacing: ['-0.5px', null, null, '1px'],
        textAlign: ['center', null, null, 'center'],
        span: {
            backgroundSize: 'cover',
            px: 2,
        },
        mb: 12,
    },
    grid: {
        gap: [6, null, 0],
        display: 'grid',
        maxWidth: 1080,
        margin: '0 auto',
        fontFamily: 'headingAlt',
        gridTemplateColumns: [
            'repeat(1, 1fr)',
            null,
            null,
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
        ],
        borderTop: (t) => [null, null, `0px solid ${t.colors.borderColor}`],
        borderLeft: (t) => [null, null, `0px solid ${t.colors.borderColor}`],
    },
};
